import Pica from 'pica'
const pica = Pica()

/**
 * Rotate/Flip an image according to the orientation
 * The orientation is normaly taken from the EXIF information.
 * @param {Canvas} canvas
 * @param {Number} orientation
 */
function rotateCanvas(canvas, orientation) {
  const ctx = canvas.getContext('2d')
  const width = canvas.width
  const height = canvas.height

  switch (Number(orientation)) {
    // explained here: https://i.stack.imgur.com/6cJTP.gif
    case 1:
      // Normal
      break

    case 2:
      // Horizontal Flip
      ctx.translate(width, 0)
      ctx.scale(-1, 1)
      break

    case 3:
      // 180° rotate left
      ctx.translate(width, height)
      ctx.rotate((180 / 180) * Math.PI) // 180/180 is 1? No shit, but how else will you know its need 180 rotation?
      break

    case 4:
      // Vertical Flip
      ctx.translate(0, height)
      ctx.scale(1, -1)
      break

    case 5:
      // Rotate 90 right + vertical flip
      canvas.width = height
      canvas.height = width
      ctx.rotate((90 / 180) * Math.PI)
      ctx.scale(1, -1)
      break

    case 6:
      // 90° rotate right
      canvas.width = height
      canvas.height = width
      ctx.rotate(Math.PI / 2)
      ctx.translate(0, -height)
      break

    case 7:
      // horizontal flip + 90 rotate right
      canvas.width = height
      canvas.height = width
      ctx.rotate((270 / 180) * Math.PI)
      ctx.translate(-width, height)
      ctx.scale(1, -1)
      break

    case 8:
      // 90° rotate left
      canvas.width = height
      canvas.height = width
      ctx.translate(0, width)
      ctx.rotate((270 / 180) * Math.PI)
      break

    default:
      break
  }
}

/**
 * Resize (and rotate) an image.
 * @param {*} img
 * @param {int} width
 * @param {int} height
 * @param {int} orientation  The orientation is currently unused
 */
function resize(img, width = 2000, height = 2000, orientation = 1) {
  const canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height

  // console.log('Image:', img.width, img.height, orientation)
  // console.log('Canvas:', canvas.width, canvas.height)

  // rotateCanvas(canvas, orientation)
  canvas.getContext('2d').drawImage(img, 0, 0)

  const widthRatio = width / canvas.width
  const heightRatio = height / canvas.height

  if(widthRatio >= 1 && heightRatio >= 1) {
    return Promise.resolve(canvas)
  } else {
    const destCanvas = document.createElement('canvas')
    if(widthRatio < heightRatio) {
      // console.log(`Resizing to ${w * widthRatio}x${h * widthRatio}`)
      destCanvas.width = canvas.width * widthRatio
      destCanvas.height = canvas.height * widthRatio
    } else {
      // console.log(`Resizing to ${w * heightRatio}x${h * heightRatio}`)
      destCanvas.width = canvas.width * heightRatio
      destCanvas.height = canvas.height * heightRatio
    }
    return pica.resize(canvas, destCanvas)
  }
}


const SOS = 0xFFDA
const APP1 = 0xFFE1
const EXIF = 0x45786966

/**
 * Get the exif data of a jpeg image blob as a separate blob
 * @param {Blob} blob
 */
function retrieveExif(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', ({ target: { result: buffer } }) => {
      const view = new DataView(buffer)
      let offset = 0
      if(view.getUint16(offset) !== 0xFFD8) {
        return reject(new Error('Not a valid JPEG'))
      }
      offset += 2
      while(true) {
        const marker = view.getUint16(offset)
        if(marker === SOS) break
        const size = view.getUint16(offset + 2)
        if(marker === APP1 && view.getUint32(offset + 4) === EXIF) {
          return resolve(blob.slice(offset, offset + 2 + size))
        }
        offset += 2 * size
      }
      return resolve(new Blob())
    })
    reader.readAsArrayBuffer(blob)
  })
}

/**
 * Copy the exif data from the src image to the dst image.
 * @param {Blob} src
 * @param {Blob} dst
 */
async function copyExif(src, dst) {
  const exif = await retrieveExif(src)
  return new Blob([dst.slice(0, 2), exif, dst.slice(2)], {
    type: 'image/jpeg',
  })
}


export {
  pica,
  resize,
  rotateCanvas,
  retrieveExif,
  copyExif,
}
