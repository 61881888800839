<template>
  <v-card class="preview-card">
    <div class="image-wrapper" ref="wrapper" @click.stop="onWrapperClick">
      <div v-show="src === null || src === undefined" class="placeholder">
        <v-icon x-large>mdi-image</v-icon>
      </div>
      <!-- Note: the following div is a placeholder which will be replaced upon image load -->
      <div />
    </div>
    <div class="closeBtn">
      <v-btn icon small color="error" @click.stop="$emit('remove', src)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>

export default {
  name: 'ImagePreview',
  props: {
    src: {
      type: [String, File, HTMLCanvasElement],
    },
  },
  data() {
    return {}
  },
  computed: {
    wrapper() { return this.$refs.wrapper },
    canvas() { return this.wrapper.lastChild },
    ctx() { return this.canvas.getContext('2d') },
  },
  watch: {
    src() {
      this.updatePreview()
    },
  },
  mounted() {
    this.updatePreview()
  },
  methods: {
    updatePreview() {
      this.canvas.height = this.canvas.width
      if (this.src === undefined || this.src === null) {
        // Nothing todo
      } else if (typeof this.src === 'string') {
        const elem = document.createElement('img')
        elem.src = this.src
        this.wrapper.replaceChild(elem, this.wrapper.lastChild)
      } else if (this.src instanceof HTMLCanvasElement) {
        this.wrapper.replaceChild(this.src, this.wrapper.lastChild)
      } else {
        console.log('Unhandled case:', this.src)
      }
    },
    onWrapperClick() {
      if (this.src === undefined || this.src === null) {
        return
      }
      console.log('Clicked Wrapper')
    },
  },
}
</script>

<style lang="scss">
.preview-card {
  .image-wrapper {
    position: relative;

    &::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    .placeholder {
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
    }

    canvas,
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .closeBtn {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}
</style>
