import axios from '@/plugins/axios'

const END_POINT = '/media'


function uploadFile(fileBlob, abortSignal, onUploadProgress) {
  const form = new FormData()
  form.append('file', fileBlob, fileBlob.name)
  return axios.post(END_POINT, form, {
    signal: abortSignal,
    onUploadProgress: onUploadProgress,
  }).then(response => response.data)
}

function removeFile(mediaId) {
  return axios.delete(`${END_POINT}/${mediaId}`)
}


function setPhotoPublished(mediaId, status) {
  const meta = {
    published: status,
  }
  return axios.put(`${END_POINT}/${mediaId}/meta`, meta).then(response => response.data)
}


export default {
  uploadFile,
  removeFile,
  setPhotoPublished,
}
