<template>
  <v-container class="pa-3 pr-6">
    <template v-if="!!event">
      <div class="d-flex pt-6 pb-4">
        <div class="d-flex mr-3">
          <org-icon :organisation="event.organisation" class="xlarge" />
        </div>
        <div class="flex-grow-1 ml-2">
          <event-title text-size="text-h5" :event="event"></event-title>
          <div class="text-body-1">{{ event.date }}</div>
        </div>
        <div class="d-flex align-center">
          <v-btn
            :to="{ name: 'EventView', params: { id: event.id } }"
            tile
            elevation="0"
            outlined
            class="ma-1"
            >Zur Tour</v-btn
          >
        </div>
      </div>

      <draggable
        v-model="photos"
        @start="drag = true"
        @end="drag = false"
        @change="orderChanged"
        v-bind="dragOptions"
        class="pt-2"
      >
        <transition-group tag="v-row" name="photos-list">
          <v-col
            cols="12"
            sm="6"
            lg="4"
            v-for="photo in photos"
            :key="photo.large"
          >
            <v-card>
              <v-img
                :src="photo.large"
                :lazy-src="photo.small"
                aspect-ratio="1"
                contain
                class="grey lighten-3"
                @click.stop="photoClick(photo)"
              ></v-img>
              <v-card-actions>
                <div>{{ photo.uploadedBy.name }}</div>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  large
                  :href="photo.original"
                  class="ma-1 no-drag"
                  download
                >
                  <v-icon>mdi-cloud-download</v-icon>
                </v-btn>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        icon
                        large
                        @click="togglePublish(photo)"
                        :color="photo.published ? 'green' : 'orange'"
                        :disabled="$ability.cannot('publish', 'Photo')"
                        class="ma-1 no-drag"
                      >
                        <v-icon v-if="photo.published">mdi-eye</v-icon>
                        <v-icon v-else>mdi-eye-off</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>{{
                    photo.published
                      ? "Foto veröffentlicht."
                      : "Foto nicht öffentlich."
                  }}</span>
                </v-tooltip>
                <v-btn
                  icon
                  large
                  @click="deletePhoto(photo)"
                  color="red"
                  class="ma-1 no-drag"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </transition-group>
      </draggable>

      <v-overlay v-if="selectedPhoto">
        <div v-click-outside="() => (selectedPhoto = null)">
          <v-img
            :src="selectedPhoto ? selectedPhoto.original : ''"
            contain
            @click.stop="selectedPhoto = null"
            max-height="95vh"
            max-width="95vw"
          ></v-img>
        </div>
      </v-overlay>

      <photo-upload
        v-on:photo-uploaded="onUploadComplete"
        v-on:photo-added="onPhotoAdded"
        ref="imageUpload"
      >
      </photo-upload>
    </template>
    <template v-else>
      <v-row class="ma-10" align="center" justify="center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-row>
    </template>
    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>

import draggable from 'vuedraggable'

import EventTitle from '@/components/events/EventTitle.vue'
import Confirm from '@/components/Confirm.vue'
import PhotoUpload from '@/components/PhotoUpload.vue'
import eventService from '@/services/event'
import mediaService from '@/services/media'

export default {
  components: { EventTitle, Confirm, PhotoUpload, draggable },
  name: 'EventPhotos',
  props: {},
  data() {
    return {
      event: null,
      drag: false,
      dragOptions: {
        animation: 200,
        group: 'photos',
        disabled: false,
        ghostClass: 'ghost',
        filter: '.no-drag',
      },
      photos: [],
      selectedPhoto: null,
    }
  },
  computed: {},
  methods: {
    photoClick(photo) {
      this.selectedPhoto = photo
    },
    togglePublish(photo) {
      mediaService.setPhotoPublished(photo.uniqueName, !photo.published).then(data => {
        photo.published = data.published
      })
    },
    deletePhoto(photo) {
      this.$refs.confirm.open(
        'Foto löschen',
        'Möchtest du dieses Foto löschen? Diese Aktion kann nicht rückgänging gemacht werden!',
        { color: 'red', width: 300 },
      ).then((confirm) => {
        if (confirm) {
          mediaService.removeFile(photo.uniqueName).then(_ => {
            this.photos = this.photos.filter(p => p !== photo)
          })
        }
      })
    },
    onPhotoAdded(item) {
      // console.log(item)
    },
    onUploadComplete(item) {
      const imageMeta = item.meta
      // console.log(imageMeta)
      eventService.addPhoto(this.$route.params.id, imageMeta.id)
        .then(photo => {
          photo.original = `/media/${photo.uniqueName}`
          photo.large = `/media/${photo.uniqueName}?thumb=500x500`
          photo.small = `/media/${photo.uniqueName}?thumb=10x10`
          this.photos.push(photo)
        })
    },
    orderChanged(event) {
      // console.log(event)
      // console.log(this.photos)
      eventService.setPhotoOrder(this.$route.params.id, this.photos.map(p => p.id))
        .then(photos => {
          this.photos = photos.map(photo => {
            photo.original = `/media/${photo.uniqueName}`
            photo.large = `/media/${photo.uniqueName}?thumb=500x500`
            photo.small = `/media/${photo.uniqueName}?thumb=10x10`
            return photo
          })
        })
    },
  },
  mounted() {
    eventService.get(this.$route.params.id).then(event => {
      this.event = event
    })
    eventService.getPhotos(this.$route.params.id).then(photos => {
      for (const photo of photos) {
        photo.original = `/media/${photo.uniqueName}`
        photo.large = `/media/${photo.uniqueName}?thumb=500x500`
        photo.small = `/media/${photo.uniqueName}?thumb=10x10`
        this.photos.push(photo)
      }
    })
  },
}
</script>

