<template>
  <v-row>
    <v-col cols="12">
      <form
        enctype="multipart/form-data"
        novalidate
        @dragover="dragStart"
        @dragenter="dragStart"
        @dragleave="dragEnd"
        @dragend="dragEnd"
        @drop="dragEnd"
      >
        <div :class="{ dropzone: true, 'is-dragover': dragActive }">
          <input
            type="file"
            class="invisible-input"
            ref="fileInput"
            accept="image/*"
            multiple
            @change="onFileChange"
            @focus="dragStart"
            @blur="dragEnd"
          />
          <p class="subheading text-center my-2">
            Drag &amp; Drop <br />oder klicken um Fotos hinzuzufügen.
          </p>

          <v-row>
            <v-col
              cols="4"
              sm="3"
              xl="2"
              v-for="item in images"
              :key="item.file.name"
            >
              <image-preview
                :src="item.thumbnail"
                @remove="deleteImage(item)"
              />
              <v-progress-linear
                v-show="item.status !== 'finished'"
                class="uploadbar"
                :value="item.progress"
                color="primary"
                :indeterminate="(item.status == 'loadFile' && true) || false"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </div>
      </form>
    </v-col>
    <v-col cols="12" class="text--secondary">
      <div class="subheading">Hinweise zum hochladen von Bilder:</div>
      <ul>
        <li>
          Fotos werden vor dem Upload automatisch auf eine maximale Grösse von
          {{ uploadSize }}x{{ uploadSize }}px verkleinert.
        </li>
        <li>
          Alle Fotos sind öffentlich zugänglich! Identifizierbare Personen
          müssen mit der Veröffentlichung einverstanden sein! Keine Bilder von
          Abrechnungen oder sonstigen internen Dokumenten. Keine Bilder von
          Unfällen oder ähnlichem!
        </li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
import axios from '@/plugins/axios'
import cloneDeep from 'lodash/cloneDeep'
import ImagePreview from './ImagePreview'
import * as imgPro from '@/utils/imageProcessing'
import { loadFile } from '@/utils/files'
import mediaService from '@/services/media'

import smartcrop from 'smartcrop'
import piexif from 'piexifjs'


const NEW_IMAGE = {
  file: undefined,
  exif: undefined,
  canvas: undefined,
  thumbnail: null,
  status: 'loadFile',
  id: undefined,
  progress: 0,
}

export default {
  name: 'PhotoUpload',
  components: { ImagePreview },
  props: {
    thumbSize: {
      type: Number,
      default: 500,
    },
    uploadSize: {
      type: Number,
      default: 3000,
    },
    uploadQuality: {
      type: Number,
      default: 0.8,
    },
  },
  data() {
    return {
      dragActive: false,
      images: [],
    }
  },
  computed: {
  },
  methods: {
    dragStart() { this.dragActive = true },
    dragEnd() { this.dragActive = false },
    async onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files || []
      const newItems = []

      // Push all files to the images
      for (const file of files) {
        const item = cloneDeep(NEW_IMAGE)
        item.file = file
        // Push the item to the overall list
        this.images.push(item)
        // And to the current list
        newItems.push(item)
      }

      for (const item of newItems) {
        // Load the image
        const image = await this.loadFromFile(item.file)

        // Extract EXIF Data
        item.exif = piexif.load(image.src)

        // Resize and Rotate
        let orientation = item.exif['0th'][piexif.ImageIFD.Orientation]
        orientation = orientation === undefined ? 1 : orientation
        item.exif['0th'][piexif.ImageIFD.Orientation] = 1

        // Note: The orientation is currently NOT handled in the resize method.
        //       It seems like the browser allready performs the rotation.
        const canvas = await imgPro.resize(image, this.uploadSize, this.uploadSize, orientation)
        item.canvas = canvas

        this.$emit('photo-added', item)

        // Convert to a blob for the upload
        const blob = await imgPro.pica.toBlob(canvas, 'image/jpeg', this.uploadQuality)

        // Fill in missing data on the blob object.
        blob.lastModifiedDate = item.file.lastModifiedDate
        blob.name = item.file.name
        item.uploadData = blob

        // Start the upload and create a thumbnail
        this.uploadImage(item)
        this.createThumbnail(item)
      }

      // Reset the form value, otherwise a photo can not be added, deleted and added again.
      e.target.value = ''
    },
    loadFromFile(src) {
      return loadFile(src, true).then(dataUrl => {
        const img = new Image()
        return new Promise(resolve => {
          img.onload = () => resolve(img)
          img.src = dataUrl
        })
      })
    },
    createThumbnail(obj) {
      const canvas = obj.canvas
      return smartcrop.crop(canvas, { width: this.thumbSize, height: this.thumbSize })
        .then(crops => {
          const crop = crops.topCrop
          obj.topCrop = crop
          const tcanvas = document.createElement('canvas')
          tcanvas.width = this.thumbSize
          tcanvas.height = this.thumbSize
          const ctx = tcanvas.getContext('2d')
          ctx.drawImage(canvas, crop.x, crop.y, crop.width, crop.height, 0, 0, this.thumbSize, this.thumbSize)
          return tcanvas
        })
        .then(canvas => {
          obj.thumbnail = canvas.toDataURL('image/jpeg', this.uploadQuality)
          this.$emit('thumbnail-created', obj)
          return obj
        })
    },
    uploadImage(item) {
      item.status = 'upload'
      item.cancelController = new AbortController()
      mediaService.uploadFile(item.uploadData, item.cancelController.signal,
        event => { item.progress = event.loaded / event.total * 100 },
      ).then(fileMeta => {
        item.meta = fileMeta
        item.status = 'finished'
        this.$emit('photo-uploaded', item)
        const self = this
        setTimeout(() => {
          self.removeFromUploadArea(item)
        }, 1000)
      }).catch(error => {
        if (!axios.isCancel(error)) {
          throw error
        }
      })
    },
    deleteImage(item) {
      switch (item.status) {
        case 'loadFile':
          this.removeFromUploadArea(item)
          break
        case 'upload':
          item.cancelController.abort()
          this.removeFromUploadArea(item)
          break
        default:
          mediaService.removeFile(item.meta.unique_name)
            .then(_ => {
              this.removeFromUploadArea(item)
            })
          break
      }
    },
    removeFromUploadArea(image) {
      this.images = this.images.filter(obj => obj !== image)
    },
  },
}
</script>


<style lang="scss" scoped>
$dragover-color: #d7dfde;

.dropzone {
  outline: 2px dashed lighten(grey, 20);
  outline-offset: -5px;
  color: dimgray;
  padding: 10px;
  min-height: 80px;
  position: relative;

  &:hover,
  &:focus {
    background: $dragover-color;
  }

  .invisible-input {
    opacity: 0;
    margin: -5px 0 0 -5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    position: absolute;
    cursor: pointer;
  }
}

.dropzone.is-dragover {
  background: $dragover-color;
}

.uploadbar {
  margin: 0;
  margin-top: -7px;
}
</style>

